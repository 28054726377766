<template>
    <div class="course-list-component">
        <ul class="course-list">
            <li class="course-item" v-for="(item, index) in list" :key="index" @click="toProductList(item.id)" v-show="index < maxNum">
                <img :src="eduCoverPath + item.cover" alt="">
                <h2>{{ item.name }}</h2>
                <p>{{ item.description }}</p>
                <span class="price" v-if="showPrice">￥{{ item.price }}</span>
            </li>
        </ul>
    </div>
</template>
<script>
    import {urls, eduCoverPath} from '@/lib/common'
    export default {
        props: {
            list: {
                type: Array,
                default: () => {
                    return []
                }
            },
            showPrice: {
                type: Boolean,
                default: false
            },
            maxNum: {
                type: Number,
                default: 9
            }
        },
        methods : {
            toProductList(id) {
                this.checkCourseIsBuyed(id).then(res => {
                    if (res === true) {
                        this.$router.push({
                            name: 'CourseList',
                            params: {
                                id: encodeURIComponent(btoa(id))
                            }
                        })
                    }
                })
            },
            checkCourseIsBuyed(id) {
                return new Promise((resolve, reject) => {
                    this.$http.get(urls.checkCourseIsBuyed + id).then(res => {
                        console.log(res)
                        if (res.data.status === 200) {
                            if (res.data.data) {
                                resolve(true)
                            } else {
                                this.$message.warning('该课程为付费课程，请购买后学习.')
                                resolve(false)
                            }
                        } else if (res.data.status !== 401 && res.data.status !== 106) {
                            this.$message.warning(res.data.message)
                            resolve(false)
                        }
                    }).catch(err => {
                        reject(err);
                    })
                })
            },
        },
        data() {
            return {
                eduCoverPath
            };
        },
        mounted() {

        }
    };
</script>
<style lang="less" scoped>
    .course-list-component {
        .course-list {
            display: flex;
            flex-wrap: wrap;
            .course-item {
                width: 30%;
                background: #fff;
                padding: 10px 10px 15px;
                margin-bottom: 4vw;
                border-radius: 8px;
                position: relative;
                overflow: hidden;
                box-sizing: border-box;
                margin-right: 5%;
                cursor: pointer;
                font-size: 14px;
                box-shadow: 0 5px 15px 2px rgba(190, 238, 252, 0.4);
                &:nth-child(3n) {
                    margin-right: 0;
                }
                img {
                    border-radius: 6px;
                }
                h2 {
                    margin: 10px 0;
                    font-size: 18px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    word-break: break-all;
                }
                p {
                    height: 3em;
                    line-height: 150%;
                    color: #231E3F;
                    display: -webkit-box;
                    overflow: hidden;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                }
                .price {
                    display: inline-block;
                    transform: translateX(120%);
                    transition: all .3s linear;
                    position: absolute;
                    right: 0;
                    top: 50%;
                    padding: 6px 10px;
                    background: #F7B527;
                    border-radius: 2vw 0 0 2vw;
                    color: #fff;
                }
                &:hover {
                    .price {
                        transform: translateX(0);
                    }
                }
            }
        }
    }

    .fontFn (@px, @attr: font-size) {
        @rem: (@px / 37.5);
        @{attr}: ~"@{rem}rem";
    }

    @media screen and (max-width: 750px){
        .course-list-component{
            .course-list{
                width: 90%;
                margin:0 auto;
                display:block;
                .course-item{
                    display:inline-block;
                    vertical-align:top;
                    width: 48%;
                    margin-right:4% !important;
                    &:nth-child(2n){
                        margin-right:0 !important;
                    }
                    h2{
                        .fontFn(16);
                    }
                    p{
                        .fontFn(12);
                    }
                }
            } 
        } 
    }
</style>